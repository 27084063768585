@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.header {
    margin-bottom: 2rem;
    border-bottom: .3rem solid $gray-2;
    text-align: center;
    &Author,
    &Title {
        font-weight: bold;
    }
    &Title {
        color: darken($primary, 5%)
    }
}

@include breakpoint-up(xl) {
    .header {
        text-align: left;
    }
}

button {
  padding: 0;
  border: none;
  cursor: pointer;
}
.button {
    background: #80b814;
    border: 1px solid #80b814;
    border-radius: 9px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    padding: 6px 20px;
    text-transform: uppercase;
    &:hover {
      background: lighten(#80b814, 5%);
    }
    &-outline {
      background: #ffffff;
      color: #80b814;
      &:hover {
        background: #f2f2f2;
      }
    }
    &:disabled {
      background: #d4d4d4;
      border-color: #d4d4d4;
      color: #979797;
      pointer-events: none;
    }
    &-nocaps {
        text-transform: none;
    }
    &-fullWidth {
      width: 100%;
    }
    &-large {
      font-size: 28px;
      line-height: 42px;
    }
    &-red {
      background: #C32931;
      border-color: #C32931;
      color: #fff;
      &:hover {
          background: lighten(#C32931, 5%);
        }
    }
  }
  
@import '../../styles/variables';
@import '../../styles/mixins';

.dashboard {
    flex-direction: column;
    display: flex;
    width: 100%;
    // max-width: 100rem;
    &Main {
        order: 1;
    }
    &Side {
        order: 0;
        margin-bottom: 3rem;
        &Panel {
            text-align: center;
            padding: 2rem;
            background: #FAFAFA;
            border-radius: 3rem;
            &Title {
                font-weight: bold;
                font-size: 2.4rem;
                margin-bottom: 2rem;
            }
            &Avatar {
                margin: 2rem auto 3rem auto;
                max-width: 10rem;
                border-radius: 50%;
            }
        }
    }
    &BookCard {
        align-items: center;
        background: #FFFFFF;
        border-radius: 1rem;
        box-shadow:  0 0 .2rem rgba(0,0,0,.4);
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
        padding: 1rem;
        position: relative;
        &Title {
            color: $body-color;
            font-size: 2.4rem;
            font-weight: bold;
        }
        &SubTitle {
            font-size: 1.8rem;
            font-weight: bold;
            margin: 0;
            padding: 0;
        }
        &Img {
            max-height: 9rem;
            &Book {
                position: absolute;
                right: .1rem;
                transform: rotate(10deg);
            }
        }
    }
}

@include breakpoint-up(lg) {
    .dashboard {
        flex-direction: row;
        &Main {
            order: 0;
            flex-basis: 65%;
            max-width: 65%;
            display: flex;
            padding-right: 3rem;
            margin-bottom: 0;
        }
        &Side {
            order: 1;
            flex-basis: 35%;
            max-width: 35%;
            &Panel {
                &Avatar {
                    max-width: 13rem;
                }
            }
        }
    }
}

.ispBanner {
    margin-top: 2rem;
    color: #fff;
    border-radius: 3rem;
    background: #FD1E69;
    text-align: center;
    padding: 2rem;
    display: block;
    img {
        display: block;
        margin: auto;
    }
}

.selectStoryBtn {
    display: flex;
    align-items: center;
    border-radius: 1rem;
    margin-bottom: .5rem;
    text-align: center;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #EBEBEB;
    font-weight: bold;
    padding: 1.5rem 1.5rem 1.5rem 3rem;
    svg {
        position: absolute;
        left: 1rem;
        top: 1.5rem;
    }
    &Active {
        background: #7D9826;
        color: #fff;
    }
}
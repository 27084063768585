@import '../../styles/variables.scss';

.tab {
    border-bottom: solid .3rem transparent;
    cursor: pointer;
    font-size: 2rem;
    margin-bottom: -.1rem;
    font-weight: bold;
    padding: 0 1.8rem .4rem 1.8rem;
    &:hover {
        color: $primary;
    }
}
.active {
    border-bottom-color: $primary;
}

.disabled {
    color: #979797;
    pointer-events: none;
}
